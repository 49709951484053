<template>
    <div>
    {{params.value}}
    </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>