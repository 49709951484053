<template>
  <vs-chip v-show="params.value" transparent :color="params.color" class="mt-2">
    {{params.value}}
  </vs-chip>
</template>

<script>
import Vue from "vue";

export default Vue.extend({});
</script>