import gql from "graphql-tag";
import graphqlClient from "../../../graphqlClient";

async function list() {
  const response = await graphqlClient.query({
    query: gql`
      {
        payment_method {
          id
          name
        }
      }
    `,
  });
  return response.data.payment_method;
}

async function listCurrencies(countryId) {
  const response = await graphqlClient.query({
    query: gql`
      {
        country_currency(where:{country_id:{_eq:"${countryId}"}}) {
        currency{ 
          id
          name
          code
          }
        }
      }
    `,
  });
  return response.data.country_currency;
}

export default {
  list,
  listCurrencies,
};
