<template>
  <div>
    <vx-card title="" title-color="black">
      <vs-row w="12">
        <vs-col vs-w="8">
          <p class="mb-1">&nbsp;</p>
        </vs-col>
        <vs-col vs-w="4">
          <h5 style="text-align: right; color: #626262">
            Total de registros:
            <span> {{ rowsCount }}</span>
          </h5>
        </vs-col>
      </vs-row>
    </vx-card>
    <br />

    <ag-grid-vue
      class="ag-theme-material ag-grid-table h-screen"
      :defaultColDef="defaultColDef"
      :columnDefs="columnDefs"
      :rowData="reservations"
      :pagination="true"
      rowSelection="multiple"
      :frameworkComponents="frameworkComponents"
      :rowHeight="50"
      @modelUpdated="onModelUpdated"
      :paginationPageSize="itemsPerPage"
      :floatingFilter="true"
    ></ag-grid-vue>

    <vs-popup title="Eliminar" :active.sync="popupDeleteReservation">
      <vs-row>
        <vs-textarea label="Motivo" v-model="deleteDescription" />

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h6>
            ¿Deseas eliminar
            {{ $tc("message.reservation", 1, user.countryCode) }}?
          </h6>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <small color="danger" v-if="deleteError"
            >El motivo de eliminación es requerido*</small
          >
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="danger"
            @click.native="deleteReservation(reservationSelected)"
            class="mt-2"
            icon-pack="feather"
            icon="icon-trash"
            >Sí, eliminar</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>

    <vs-popup title="Desestimiento" :active.sync="popupDisableReservation">
      <vs-row>
        <vs-textarea
          label="Razón de desestimiento"
          v-model="disableDescription"
        />

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <h6>
            ¿Deseas desistir de
            {{ $tc("message.reservation", 1, user.countryCode) }}?
          </h6>
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <small color="danger" v-if="disableError"
            >Razón de desestimiento es obligatoria*</small
          >
        </vs-col>

        <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
          <vs-button
            color="danger"
            @click.native="disableReservation(reservationSelected)"
            class="mt-2"
            icon-pack="feather"
            icon="icon-trash"
            >Sí, desistir</vs-button
          >
        </vs-col>
      </vs-row>
    </vs-popup>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { AgGridVue } from "ag-grid-vue";
import AgChip from "../../../components/AgGridComponents/AgChip.vue";
import AgText from "../../../components/AgGridComponents/AgText.vue";
import AgImage from "../../../components/AgGridComponents/AgImage.vue";
import AgGroupButton from "../../../components/AgGridComponents/AgGroupButton.vue";
import { reservationApi } from "../services";
import { api as projectApi } from "../../projects/services";
import currencies from "../../../helpers/currencies";
import dayjs from "../../../helpers/days";

export default {
  components: {
    AgGridVue,
  },
  async mounted() {
    const projectId = this.$route.params.id;
    this.getReservations(projectId);
    this.project = await projectApi.get(projectId);

    this.columnDefs = [
      {
        headerName: "Unidad",
        valueGetter: (params) =>
          this.getProperty(params.data.quote.quote_details)
            ? this.getProperty(params.data.quote.quote_details)
            : "-",
        width: 160,
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Vendedor",
        with: 450,
        hide: this.user.role == "ADMIN" ? false : true,
        valueGetter: (params) => this.getSellerFullname(params.data.quote.user),
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Cliente",
        valueGetter: (params) =>
          this.getCustomerFullName(params.data.customer_reservations),
        width: 200,
        cellRenderer: (params) =>
          this.getCustomerFullName(params.data.customer_reservations),
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Código cliente",
        valueGetter: ({ data }) => {
          return data.client_reference
            ? data.client_reference
            : data.customer_simple_id;
        },
        width: 200,
        cellRenderer: ({ data }) => {
          return data.client_reference
            ? data.client_reference
            : data.customer_simple_id;
        },
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: `Total de ${this.$tc(
          "message.Reservation",
          1,
          this.user.countryCode,
        )}`,
        field: "reservation_total",
        filter: "agNumberColumnFilter",
        filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        valueGetter: ({ data }) => {
          return data.quote.total;
        },
        valueFormatter: ({ data }) => {
          return this.getFormatCurrency(data.quote.total);
        },
        width: 190,
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Monto Pagado",
        filter: "agNumberColumnFilter",
        filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        valueGetter: (params) => {
          const payments = params.data.reservation_downpayments.map((item) => {
            if (!item.reservation_downpayment_payments.length) return null;

            return item.reservation_downpayment_payments.map((payment) => {
              return payment.reservation_payment;
            });
          });

          return payments
            .flat()
            .filter((item) => {
              return item
                ? item.canceled == false && item.deleted == false
                : false;
            })
            .reduce((accum, payment) => payment.amount + accum, 0);
        },
        valueFormatter: (item) => {
          return this.getFormatCurrency(item.value);
        },
        width: 190,
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Extras",
        filter: "agNumberColumnFilter",
        filterParams: { buttons: ["apply", "reset"], closeOnApply: true },
        valueGetter: (params) => {
          return params.data.quote.quote_extras.reduce(
            (accum, extra) => extra.value + accum,
            0,
          );
        },
        valueFormatter: (item) => {
          return this.getFormatCurrency(item.value);
        },
        width: 190,
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Descuento",
        filter: "agNumberColumnFilter",
        width: 190,
        valueGetter: (params) => {
          return params.data.quote.total_discounts;
        },
        valueFormatter: (params) => {
          return this.getFormatCurrency(params.data.quote.total_discounts);
        },
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Fecha creación",
        filter: "agDateColumnFilter",
        width: 220,
        valueGetter: (params) => {
          return this.removeTime(dayjs(params.data.created_at));
        },
        valueFormatter: (params) => {
          return this.getDateFormat(params.data.created_at);
        },
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Fecha Saldo/Entrega",
        filter: "agDateColumnFilter",
        width: 220,
        valueGetter: (params) => {
          return this.removeTime(
            this.getFinancingDate(params.data.reservation_downpayments),
          );
        },
        valueFormatter: (params) => {
          return this.getDateFormat(
            this.getFinancingDate(params.data.reservation_downpayments),
          );
        },
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Fecha Ultimo Pago",
        filter: "agDateColumnFilter",
        width: 220,
        valueGetter: (params) => {
          return this.removeTime(
            this.getLastPaidDate(params.data.reservation_downpayments),
          );
        },
        valueFormatter: (params) => {
          return this.getDateFormat(
            this.getLastPaidDate(params.data.reservation_downpayments),
          );
        },
        cellClassRules: {
          "c-lost-row": (params) =>
            params.data.reservation_status.id === "LOST",
          "c-wong-row": (params) => params.data.reservation_status.id === "WON",
          "c-open-row": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Torre",
        with: 450,
        valueGetter: (params) =>
          params.data.quote.quote_details
            ? this.getPropertyTower(params.data.quote.quote_details)
            : "-",
      },

      {
        headerName: "Reserva",
        field: "status",
        cellRenderer: "agText",
        cellRendererParams: ({ data }) => {
          return {
            value: data.reservation_status.name,
          };
        },
        cellClassRules: {
          "bg-lost-row txc": (params) =>
            params.data.reservation_status.id === "LOST",
          "bg-won-row txc": (params) =>
            params.data.reservation_status.id === "WON",
          "bg-open-row txc": (params) =>
            params.data.reservation_status.id === "OPEN",
        },
      },
      {
        headerName: "Pagos",
        field: "status",
        cellRenderer: "",
        cellRendererParams: ({ data }) => {
          if (data.deleted) {
            return {
              color: "danger",
              value: "Eliminado",
            };
          }

          return {
            color: data.payment_status
              ? this.statusColorPayment(data.payment_status.id)
              : null,
            value: data.payment_status ? data.payment_status.name : null,
          };
        },
        valueGetter: ({ data }) => {
          if (data.deleted) {
            return "Eliminado";
          }

          return data.payment_status ? data.payment_status.name : null;
        },
        cellClassRules: {
          "bg-ontime-row txc": (params) =>
            params.data.payment_status.id === "ONTIME",
          "bg-delayed-row txc": (params) =>
            params.data.payment_status.id === "DELAYED",
          "bg-paid-row txc": (params) =>
            params.data.payment_status.id === "PAID",
        },
      },
      {
        headerName: "Detalle",
        field: "id",
        cellRenderer: "agGroupButton",
        filter: false,
        width: 130,
        cellRendererParams: ({ data }) => {
          let buttons = [];

          const viewPayments = {
            color: "primary",
            icon: "icon-eye",
            event: "event-quotes",
          };
          const deleteButton = {
            color: "danger",
            icon: "icon-trash",
            event: "event-delete",
          };

          const disableButton = {
            color: "danger",
            icon: "icon-lock",
            event: "event-disable",
          };
          if (data.deleted) {
            return;
          }

          if (data.reservation_status.id == "LOST") {
            buttons.push(viewPayments);
            return { buttons };
          }

          buttons.push(viewPayments);

          if (this.user.role === "ADMIN") {
            buttons.push(disableButton);
            buttons.push(deleteButton);
          }
          return { buttons };
        },
      },
    ];
    this.onAgGroupEventButton();
    this.$store.state.project.breadcrumbs = [
      {
        pageName: this.$tc(
          "message.Reservation",
          2,
          this.user.countryCode,
        ).toLowerCase(),
        route: "/reservas",
      },
      {
        pageName: `${this.project ? this.project.name : ""}`,
        route: `/reservas-en-proyecto/${this.$route.params.id}`,
      },
    ];
  },
  data() {
    return {
      reservations: [],
      project: null,
      itemsPerPage: 7,
      rowsCount: 0,
      defaultColDef: {
        filter: true,
        sortable: true,
        width: 120,
        minwidth: 75,
        maxWidth: 100,
        filterParams: {
          alwaysShowBothConditions: true,
          defaultJoinOperator: "OR",
        },
      },
      columnDefs: null,
      frameworkComponents: null,
      popupDeleteReservation: false,
      reservationSelected: null,
      popupDisableReservation: false,
      disableDescription: "",
      disableError: false,
      deleteDescription: "",
      deleteError: false,
    };
  },
  beforeMount() {
    this.frameworkComponents = {
      agChip: AgChip,
      agImage: AgImage,
      agText: AgText,
      agGroupButton: AgGroupButton,
    };
  },
  watch: {
    popupDisableReservation(val) {
      if (!val) {
        this.disableDescription = "";
      }
    },
    popupDeleteReservation(val) {
      if (!val) {
        this.deleteDescription = "";
      }
    },
  },
  computed: {
    ...Object.assign(mapState("auth", ["user"])),
  },
  methods: {
    async getReservations(projectId) {
      this.$store.dispatch("project/currentProject", { projectId });
      const { userId, role } = this.$store.getters["auth/getUser"];

      const sellerId =
        role !== "ADMIN" && role !== "SUPERVISOR" ? userId : null;

      this.reservations = await reservationApi.list(projectId, sellerId);
    },
    onAgGroupEventButton() {
      this.$root.$on("event-quotes", (data) => {
        this.$store.commit("reservation/setReservationId", data.id);
        this.$router.push({
          name: "Cartera",
          params: {
            id: data.id,
          },
        });
      });

      this.$root.$on("event-delete", (data) => {
        this.popupDeleteReservation = true;
        this.reservationSelected = data;
      });

      this.$root.$on("event-disable", (data) => {
        this.popupDisableReservation = true;
        this.reservationSelected = data;
      });
    },
    statusColor(status) {
      if (status == "OPEN") return "primary";
      if (status == "WON") return "success";
      if (status == "LOST") return "danger";
    },
    statusColorPayment(status) {
      if (status == "ONTIME") return "success";
      if (status == "PAID") return "success";
      if (status == "DELAYED") return "danger";
    },
    getSellerFullname(seller) {
      if (!seller.last_name) {
        return seller.first_name;
      }

      return `${seller.first_name} ${seller.last_name}`;
    },
    getCustomerFullName(customer) {
      if (!customer.length) return "--";

      const mainCustomer = customer[0].customer;

      if (mainCustomer.customer_business) {
        return mainCustomer.customer_business.name;
      }

      const individualCustomer = mainCustomer.customer_individual;

      if (!individualCustomer) return "--";

      return `${individualCustomer.first_name}
        ${individualCustomer.middle_name || ""}
        ${individualCustomer.first_surname}
        ${individualCustomer.second_surname || ""}
        ${individualCustomer.married_surname || ""}
        `;
    },
    async deleteReservation(reservation) {
      try {
        if (!this.deleteDescription) {
          this.deleteError = true;
          return;
        }

        await reservationApi.deleteById({
          id: reservation.id,
          description: this.deleteDescription,
        });

        this.popupDeleteReservation = false;
        this.reservationSelected = null;

        const projectId = this.$route.params.id;
        await this.getReservations(projectId);

        this.$vs.notify({
          time: 6000,
          title: `Reserva ${reservation.id} 🏢🚀.`,
          text: `Eliminada correctamente.`,
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible eliminar la reserva`,
          color: "danger",
        });
      }
    },
    async disableReservation(reservation) {
      try {
        if (!this.disableDescription) {
          this.disableError = true;
          return;
        }

        await reservationApi.disableById({
          id: reservation.id,
          description: this.disableDescription,
        });

        this.popupDisableReservation = false;
        this.disableDescription = "";
        this.reservationSelected = null;

        const projectId = this.$route.params.id;
        await this.getReservations(projectId);

        this.$vs.notify({
          time: 6000,
          title: `Reserva ${reservation.id} 🏢🚀.`,
          text: `Desistida correctamente.`,
          color: "success",
        });
      } catch (e) {
        this.$vs.notify({
          title: `Error`,
          text: `No ha sido posible disistir de reserva`,
          color: "danger",
        });
      }
    },
    getFormatCurrency(value) {
      const currencySymbol = this.$store.getters["project/currencySymbol"];

      return currencies.currencyFormatter({
        value,
        currency: currencySymbol,
      });
    },
    onModelUpdated(event) {
      this.rowsCount = event.api.getDisplayedRowCount();
    },
    getDateFormat(date) {
      return date ? dayjs(date).format("DD/MM/YYYY") : "";
    },
    removeTime(date) {
      return date ? dayjs(dayjs(date).format("DD/MM/YYYY"), "DD/MM/YYYY") : "";
    },
    getFinancingDate(downpayments) {
      const financingPayment =
        downpayments.find((i) => i.payment_type_id === "FINANCING") || null;
      if (!financingPayment) {
        return "";
      }
      return financingPayment.due_date;
    },
    getLastPaidDate(downpayments) {
      const financingPayment = downpayments
        .map((downpayment) => {
          return downpayment.reservation_downpayment_payments.map(
            (reservationPayment) => {
              return reservationPayment.reservation_payment.paid_on;
            },
          );
        })
        .reduce((maxDate, paymanetDate) => {
          return maxDate > paymanetDate ? maxDate : paymanetDate;
        });
      if (!financingPayment.length) {
        return "";
      }
      return financingPayment;
    },
    getProperty(properties) {
      if (!properties) {
        return null;
      }
      const propertyType = properties.filter((item) => {
        if (item.property) {
          const type = item.property.property_type_id;
          return type !== "PARKING" && type !== "WAREHOUSE";
        }
      });
      if (!propertyType.length) {
        return null;
      }
      return propertyType[0].property.unit_number;
    },
    getPropertyTower(properties) {
      const mainProp = properties.filter((item) => {
        if (item.property) {
          const type = item.property.property_type_id;
          return type !== "PARKING" && type !== "WAREHOUSE";
        }
      });
      if (!mainProp.length) return "-";

      const towerNumber = mainProp[0].property.property_attribute_values.filter(
        (item) => item.property_attribute === "TOWER_NUMBER",
      );

      if (!towerNumber.length) return "-";

      return towerNumber[0].value;
    },
  },
};
</script>

<style lang="scss">
.txc {
  text-align: center;
}
.bg-lost-row {
  color: #fff;
  background: #f24646;
}
.c-lost-row {
  color: #f24646;
}

.bg-ontime-row,
.bg-open-row {
  background: #f6f7f8;
  color: #000;
}
</style>